<template>
  <q-drawer
    v-model="leftDrawerOpen"
    :mini="miniState"
    :behavior="drawerbehave"
    persistent
    show-if-above
    :width="250"
    :mini-width="25"
    bordered
    :overlay="false"
    class="hover-appear"
  >
    <transition
      mode="out-in"
      enter-active-class="animated fadeIn"
      leave-active-class="animated fadeOut"
    >
      <div
        v-if="!miniState"
        class="scroll column no-wrap relative-position fit"
      >
        <div class="flex justify-end q-pt-md q-px-md lt-sm">
          <q-btn
            v-close-popup
            icon="close"
            color="grey"
            outline
            dense
            data-test="close-dialog"
            @click="leftDrawerOpen = !leftDrawerOpen"
          />
        </div>

        <q-list class="q-mx-md q-mt-md item-rounded">
          <template v-for="link in menuLinks" :key="link.id">
            <q-separator v-if="link.id === 'senderWorkflows'" class="q-my-lg" />
            <q-item
              :disable="link.displayDisable?.()"
              clickable
              :href="link.href"
              :to="link.to"
              class="text-weight-light"
              :class="{
                'text-primary active text-weight-bold': activeLink === link.id,
              }"
              active-class="text-primary active text-weight-bold"
              :data-test="link.id"
              :data-selector-target="link.id"
              @click="link.action && link.action?.()"
            >
              <q-item-section v-if="link.icon" class="col-auto q-pr-sm">
                <q-icon :name="link.icon" size="xs" />
              </q-item-section>
              <q-item-section>
                <q-item-label>{{ link.label() }}</q-item-label>
              </q-item-section>
              <div class="item-indicator"></div>
            </q-item>
          </template>
        </q-list>

        <q-space />

        <q-list class="q-mx-md q-mt-md q-mb-md item-rounded">
          <q-item
            clickable
            :href="bin.href"
            :to="bin.to"
            class="text-weight-light"
            active-class="text-primary active text-weight-bold"
            data-test="bin"
          >
            <q-item-section class="col-auto q-pr-sm">
              <q-icon name="fa-solid fa-trash" size="xs" />
            </q-item-section>
            <q-item-section>
              <q-item-label>{{ $t('common.bin') }}</q-item-label>
            </q-item-section>
            <div class="item-indicator"></div>
          </q-item>
        </q-list>
      </div>
    </transition>

    <div
      class="absolute q-mt-md gt-xs"
      style="top: 0; right: 0; transform: translateX(50%)"
    >
      <q-btn
        round
        outline
        color="grey-5"
        class="bg-white"
        :class="{
          'hover-target-xs': !miniState,
        }"
        size="sm"
        :icon="miniState ? 'chevron_right' : 'chevron_left'"
        @click="miniState = !miniState"
      />
    </div>
  </q-drawer>
</template>

<script setup lang="ts">
import { ref, watchEffect } from 'vue'
import { useI18n } from 'vue-i18n'
import { QDrawer, QDrawerProps, useQuasar } from 'quasar'

import { useDrawer } from 'composables/common/layout/useDrawer'
import { useCommonMenu } from 'composables/common/ui/useCommonMenu'

import { ExactlyOneKey } from 'types/common/commonTypes'
import { BaseLinks, LinkActions } from 'types/common/layout/layout'

type LinkIds = 'overview' | 'templates' | 'senderWorkflows' | 'integrations'

// PROPS
type Props = {
  linkActions: LinkActions<LinkIds>
  // Either to or href
  bin: ExactlyOneKey<'to' | 'href', string>
  activeLink?: LinkIds
}
const props = defineProps<Props>()

const { leftDrawerOpen, miniState } = useDrawer()
const $q = useQuasar()

const drawerbehave = ref<QDrawerProps['behavior']>('default')

watchEffect(() => {
  if ($q.screen.gt.xs) {
    drawerbehave.value = 'desktop'
    leftDrawerOpen.value = true
  } else {
    drawerbehave.value = 'mobile'
  }
})

const { t } = useI18n()

const receiverLinks: BaseLinks<LinkIds>[] = [
  {
    id: 'overview',
    label: () => t('layout.links.overview'),
    icon: 'fa-solid fa-binoculars',
  },
  {
    id: 'templates',
    label: () => t('common.template', 2),
    icon: 'fa-solid fa-file',
  },
  {
    id: 'integrations',
    label: () => t('common.integration', 2),
    icon: 'fa-solid fa-code',
  },
  {
    id: 'senderWorkflows',
    label: () => t('common.workflow', 2),
    icon: 'fa-solid fa-code-branch fa-rotate-90',
  },
]

const { menuLinks } = useCommonMenu<LinkIds>(receiverLinks, props.linkActions)
</script>

<style lang="scss" scoped>
.q-item {
  .item-indicator {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 4px;
    height: 60%;
    border-radius: 8px;
    background-color: var(--q-primary);
    opacity: 0;
    transition: opacity $generic-hover-transition;
  }
  &.active {
    .item-indicator {
      opacity: 1;
    }
  }
}
</style>
