<template>
  <p
    class="q-mb-xs"
    :class="{
      'text-primary text-weight-bold text-body2': activeStep,
      'text-muted text-caption': isFinished || !activeStep,
    }"
  >
    {{ $t('common.step').toUpperCase() }} {{ step }}
    <q-icon
      v-if="isFinished"
      name="fa-solid fa-circle-check"
      color="positive"
      size="14px"
      class="q-ml-sm"
      data-test="completed"
    />
  </p>
  <q-expansion-item
    v-model="expanded"
    group="onboarding"
    class="rounded-borders"
    header-class="rounded-borders"
  >
    <template #header>
      <q-item-section>
        <p class="text-weight-medium">
          {{ title }}
        </p>
      </q-item-section>
    </template>

    <div class="q-px-sm">
      <q-list>
        <q-item
          v-for="subStep in subSteps"
          :key="subStep.step"
          :clickable="isClickable(subStep)"
          class="rounded-borders text-caption q-py-xs"
          :data-test="subStep.step"
          style="min-height: 36px"
          @click="$emit('onClick', subStep.step)"
        >
          <q-item-section
            :class="{
              'text-strike text-muted': subStep.completed,
            }"
            :data-test="`title-${subStep.step}`"
          >
            {{ $t(`common.onboarding.subSteps.${subStep.step}`) }}
          </q-item-section>
          <q-item-section side>
            <q-icon
              name="fa-solid fa-circle-check"
              :color="subStep.completed ? 'positive' : 'muted'"
              size="14px"
            />
          </q-item-section>
        </q-item>
      </q-list>
    </div>
  </q-expansion-item>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import { useOnboardingStore } from 'stores/common/onboarding'

import {
  OnboardingSteps,
  OnboardingStepsType,
} from 'types/common/onboarding/onboarding'

// PROPS
const props = defineProps<{
  step: number
  subSteps: OnboardingStepsType[]
  activeStep: boolean
}>()
// EMITS
defineEmits<{
  onClick: [step: OnboardingSteps]
}>()

const onboardingStore = useOnboardingStore()
const { t } = useI18n()

const isFinished = computed(() =>
  props.subSteps.every((subStep) => subStep.completed)
)

const expanded = ref(props.activeStep)
watch(
  () => props.activeStep,
  (activeStep) => {
    expanded.value = activeStep
  }
)

const title = computed(() => {
  return titles[props.step]()
})
const titles: Record<number, () => string> = {
  1: () => t('common.onboarding.firstTemplate'),
  2: () => t('common.onboarding.testTemplate'),
  3: () => t('common.onboarding.embedTemplate'),
}

const isClickable = (subStep: OnboardingStepsType) => {
  // If the substep is completed, it should not be clickable
  if (subStep.completed) return false
  // STEP 1: Only the first substep should be clickable if the substep 1 is not completed
  if (props.step === 1) {
    return (
      subStep.step === props.subSteps[0].step || props.subSteps[0].completed
    )
  }
  // STEP 2: Clickable if the previous step is completed
  if (props.step === 2) {
    const index = props.subSteps.findIndex((s) => s.step === subStep.step)
    if (index === 0) {
      return onboardingStore.currentStep >= 2
    } else {
      return props.subSteps[index - 1].completed
    }
  }
  return true
}
</script>
