<template>
  <div class="onboarding-btn">
    <q-btn
      :color="menu ? 'primary' : 'grey-5'"
      outline
      class="bg-white q-py-sm"
    >
      <span
        :class="{
          'text-base-color': !menu,
        }"
      >
        {{ $t('common.onboarding.start') }}
        <div
          class="inline-block"
          :class="{
            'animated fadeOutTopRight': animation,
          }"
          style="--animate-duration: 1s"
        >
          &#x1F680;
        </div>
      </span>

      <q-menu
        v-model="menu"
        transition-show="jump-down"
        transition-hide="jump-up"
        persistent
        :offset="[0, 8]"
        class="onboarding-menu"
      >
        <q-card>
          <q-card-section
            :class="onboardingStore.onboadingFinished ? 'q-py-xl' : null"
          >
            <div v-if="onboardingStore.onboadingFinished" class="text-center">
              <p class="animated slower pulse infinite" style="font-size: 38px">
                &#127881;
              </p>
              <h3>{{ $t('common.onboarding.congrat') }}</h3>
              <p>{{ $t('common.onboarding.success') }}</p>
              <p>{{ $t('common.onboarding.success2') }}</p>

              <div>
                <q-btn
                  :href="docUrl"
                  target="_blank"
                  :label="$t('common.onboarding.discoverMore')"
                  color="primary"
                  class="q-mt-lg"
                  data-test="discover"
                />
              </div>

              <q-btn
                :label="$t('common.close')"
                outline
                color="primary"
                size="sm"
                class="q-mt-md"
                data-test="close"
                @click="menu = false"
              />
            </div>
            <q-list v-else>
              <onboarding-step
                :step="1"
                :sub-steps="onboardingStore.getFirstSteps || []"
                :active-step="onboardingStore.currentStep === 1"
                @on-click="$emit('onClick', $event)"
              />
              <q-separator spaced />
              <onboarding-step
                :step="2"
                :sub-steps="onboardingStore.getSecondSteps || []"
                :active-step="onboardingStore.currentStep === 2"
                @on-click="$emit('onClick', $event)"
              />
              <q-separator
                v-if="onboardingStore.getThirdSteps?.length"
                spaced
              />
              <onboarding-step
                v-if="onboardingStore.getThirdSteps?.length"
                :step="3"
                :sub-steps="onboardingStore.getThirdSteps"
                :active-step="onboardingStore.currentStep === 3"
                @on-click="$emit('onClick', $event)"
              />
            </q-list>
          </q-card-section>
        </q-card>
      </q-menu>
    </q-btn>
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'

import { useOnboardingStore } from 'stores/common/onboarding'

import OnboardingStep from './OnboardingStep.vue'

import { OnboardingSteps } from 'types/common/onboarding/onboarding'

// EMITS
defineEmits<{
  onClick: [step: OnboardingSteps]
}>()

const docUrl = process.env.HELP_URL

const menu = ref(true)
const onboardingStore = useOnboardingStore()

watch(
  () => onboardingStore.onboadingFinished,
  (value) => {
    if (value) {
      menu.value = true
    }
  }
)

const animation = ref(false)
watch(menu, (val) => {
  if (!val && onboardingStore.onboadingFinished) {
    animation.value = true

    setTimeout(() => {
      onboardingStore.previouslyFinished = true
    }, 500)
  }
})
</script>

<style scoped lang="scss">
.onboarding-btn {
  position: fixed;
  top: calc($headerHeight + 24px);
  right: 24px;
  z-index: 1000;
}
</style>

<style lang="scss">
.onboarding-menu {
  z-index: 1000;
}
</style>
